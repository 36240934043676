<template>
  <div>
    <div class="container-fluid row d-flex justify-content-center mt-5 pt-5">
      <div class="col-xl-8 d-flex flex-column align-items-center mt-5">
        <span class="h1 text-danger mb-5">{{ errortitel }}</span>
        <p
          class="h4 mb-5"
          style="white-space: pre-wrap; line-height: 35px"
          v-html="textBold"
        >
          {{ errortext }}
        </p>
        <button
          @click="$router.go(-2)"
          type="button"
          class="btn btn-link w-25 shadow-none"
        >
          Zurück
        </button>
        <button
          @click="goToStartseite()"
          type="button"
          class="btn btn-link w-25 shadow-none"
        >
          Zurück zur Startseite
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import page from "@/mixins/Page";

export default {
  name: "Errorpage",
  components: {},
  mixins: [page],
  props: {
    errorcode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      errortitel: "",
      errortext: "",
    };
  },
  computed: {
    navbarTitel: function () {
      return "Error";
    },
    textBold() {
      return this.errortext.replace(/#/g, "<b>").replace(/@/g, "</b>");
    },
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.setErrorText(this.errorcode);
  },
  methods: {
    goToStartseite() {
      this.$router.push({ name: "Startseite" });
    },
    setErrorText(error) {
      switch (error) {
        case "400":
          this.errortitel = "ERROR " + this.errorcode + ": BAD REQUEST";
          this.errortext =
            "Der Server ist nicht in der Lage, Ihre Anfrage zu bearbeiten. \n\nMögliche Gründe: \n\n   #- Webadresse falsch eingegeben:@ Prüfen Sie die eingegebene URL.\n   #- Zu lange URLs / Header-Lines:@ Kürzen Sie Ihre Anfrage.\n   #- Veraltete/fehlerhafte Cookies:@ Löschen Sie Ihre Cookies.\n   #- Veraltete DNS-Einträge:@ Leeren Sie Ihren DNS-Cache.\n   #- Upload von zu grossen Dateien:@ Der Server kann Ihre Datei nicht bearbeiten.";
          break;
        //Error 401 wird bereits in Api.js abgefangen
        case "402":
          this.errortitel = "ERROR " + this.errorcode + ": PAYMENT REQUIRED";
          this.errortext =
            "Zum Abrufen der Ressource ist eine Zahlung erforderlich.";
          break;
        case "403":
          this.errortitel = "ERROR " + this.errorcode + ": FORBIDDEN";
          this.errortext =
            "Sie verfügen nicht über die benötigten Berechtigungen, um auf die angeforderte Ressource zuzugreifen.";
          break;
        case "404":
          this.errortitel = "ERROR " + this.errorcode + ": NOT FOUND";
          this.errortext = "Die angeforderte Webseite existiert nicht.";
          break;
        case "405":
          this.errortitel = "ERROR " + this.errorcode + ": METHOD NOT ALLOWED";
          this.errortext =
            "Ihre Anfrage versucht auf eine gesperrte Ressource zuzugreifen oder verwendet ein Verfahren, das nicht erlaubt ist.";
          break;
      }
    },
  },
};
</script>

<style>
</style>
